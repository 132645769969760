import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/default.css"
import "../components/layout.css"
import "../components/media-queries.css"
import "../components/magnific-popup.css"

import profilePic from "../images/profilepic.jpg"
import iconWork from "../images/icon_work.jpg"
import yeti from "../images/yeti.png"

import headshotHeather from "../images/headshot_heather.jpg"
import headshotIan from "../images/headshot_ian.jpg"
import headshotBill from "../images/headshot_bill.jpg"

var ulStyle = { fontSize: '16px', fontWeight: 'bold' };
var h1Style = { textAlign: 'center', color: '#17a854' };


const IndexPage = () => (
    <Layout>
       <SEO title="Home" />

       <section id="about">
          <div className="row">
             <div className="three columns">
                <img className="profile-pic" src={profilePic} alt="" />
             </div>
             <div className="nine columns main-col">

                <h2 style={{fontSize: '28px', paddingBottom: '20px'}}>THIS IS WHAT WE ARE ABOUT</h2>

                <ul style={ulStyle}>
                   <li>• We believe Happy People Make Awesome Games </li>
                   <li>• Fun + Respecting Players </li>
                   <li>• Best in Class Execution </li>
                   <li>• Quality Games On Time and On Budget</li>
                   <li>• Culture of Collaboration + Respect </li>
                   <li>• Systems Science + Art of Great Games </li>
                   <li>• Sound Strategy + Always be Learning + Adapting</li>
                   <li>• Be Great Partners </li>
                </ul>
             </div>
          </div>
       </section>

       <section id="team">
          <div className="row teamheader"><h1 style={h1Style}>Team</h1>
          </div>

          <div className="row work">
             <div className="three columns header-col">
                <img alt="" src={headshotHeather} />
             </div>

             <div className="nine columns main-col">
                <div className="row item">
                   <div className="twelve columns">
                      <h3>Heather Price</h3>
                      <p className="info">CEO</p>
                      <p>
                         Heather has been working in games since 2001.  After over 9 years of marketing and business roles at Electronic Arts and Microsoft Game Studios, she made the leap to work directly in game development.  She joined Exploding Barrel Games as General Manager in 2011 which was acquired by Kabam in January 2013, where she was Vice President of North America Studio Operations. After leaving Kabam in 2014, Heather and her partners co-founded This Game Studio. When Heather isn’t on the clock, she is likely spoiling her cat Scout. </p>
                   </div>

                </div>

                <div className="row item">
                   <div className="twelve columns">
                   </div>
                </div>
             </div>
          </div>

          <div className="row work">
             <div className="three columns header-col">
                <img alt="" src={headshotIan}/>
             </div>
             <div className="nine columns main-col">
                <div className="row item">
                   <div className="twelve columns">
                      <h3>Ian Szufnara</h3>
                      <p className="info">CTO & Head of Product</p>
                      <p>
                         Prior to co-founding This Game Studio, Ian spent over 10 years working at EA in multiple technical and leadership roles across EA brands such as FIFA, Need for Speed, Tiger Woods, Marvel, SSX, Fight Night & UFC where he managed large console technical teams/budgets as well as scrappier mobile teams. His background in Physics and Computer Science provides the foundation for critical thinking and problem solving and his passion for games gives him the fuel to perform. When Ian isn’t thinking about This Game Studio’s technology roadmap, improving processes or interacting with the team, he is likely out taking photos.
                      </p></div>
                </div>
                <div className="row item">
                   <div className="twelve columns">
                   </div>
                </div>
             </div>
          </div>
       </section>

       <section id="jobs">
          <div className="row teamheader"><h1 style={h1Style}>Jobs</h1>
          </div>
          <div className="row jobs">
             <div className="three columns header-col">

             </div>

             <div className="nine columns main-col">
                <div className="row item">
                   <div className="twelve columns">
                      <h3>At This Game Studio, we do our best to put our gamemakers first and we offer the following perks:</h3>
                      <br/>
                      <ul style={ulStyle}>• A strong benefits plan.</ul>
                      <ul style={ulStyle}>• Little to no crunch. We believe your evenings and weekends are yours to enjoy with family and friends. We have not requested overtime hours since 2016.</ul>
                      <ul style={ulStyle}>• Uncapped paid vacation time. We all love to travel, spend time with loved ones, maximize big life events and enjoy all that beautiful British Columbia has to offer. We want to ensure that our entire team has the time for a rich and fulfilling life outside of the studio.</ul>
                      <ul style={ulStyle}>• We have an extremely flat & collaborative org where every individual’s contribution is meaningful.</ul>
                   </div>
                </div>
             </div>
          </div>

          <div className="send">
             <ul style={{fontSize: '18px', textAlign: 'center',  paddingTop: '10px'}}>Send resumes to</ul>
             <center>
                <a href="mailto:info@thisgamestudio.com" target="_top" style={{fontSize: '30px', textAlign: 'center'}}>info@thisgamestudio.com</a></center>
          </div>
       </section>
       <section id="contact">

          <div className="" style={{ padding:`0px`, align:`center`}} >
             <h4>Email</h4>
             <p><a href="mailto:info@thisgamestudio.com" target="_top">info@thisgamestudio.com</a></p>
          </div>
       </section>

    </Layout>
)

export default IndexPage
